import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class ToastService {


	// ---- Constructor ---- \\
	constructor(
		private toastController: ToastController
	) { }



	// ---- Methods ---- \\

	/**
	 * Present a toast with given message
	 * 
	 * @param message message you want to display
	 * @param color "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", and "dark"
	 */
	public async presentToast(message: string, color: string = undefined) {

		const icon = (color == 'danger') ? 'alert-outline' : 'checkmark-outline'

		const toast = await this.toastController.create({
			icon: icon,
			duration: 5000,
			position: 'top',
			message,
			color
		});

		toast.present();
	}
}
