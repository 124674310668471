import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

// Modules
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { ParticipantDistributionComponent } from './general-components/participant-distribution/participant-distribution.component';
import { ParticipantInfoComponent } from './general-components/participant-info/participant-info.component';

// Services
import { AuthInterceptor } from './services/interceptor.service';

@NgModule({
	declarations: [
		AppComponent,
		ParticipantInfoComponent,
		ParticipantDistributionComponent
	],
	entryComponents: [],
	imports: [

		// Angular
		BrowserModule,
		HttpClientModule,

		// Ionic
		IonicModule.forRoot({
			swipeBackEnabled: false
		}),
		IonicStorageModule.forRoot({
			name: '__kleandb',
			driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
		}),

		// App
		AppRoutingModule
	],
	providers: [
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
