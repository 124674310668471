import { Injectable } from '@angular/core';
import { CachedhttpService } from './cachedhttp.service';
import { environment } from 'src/environments/environment';
import { iAppInfo } from '../interfaces/app-info';


@Injectable({
	providedIn: 'root'
})
export class AppService {

	// ---- Variables ---- \\
	public appInfoFallback: iAppInfo = {
		version: '',
		contact_mail: 'team@greenland-travel.dk',
		contact_phone: '+45 33 13 10 11',
		languages: [],
		website_url: ''
	}


	// ---- Constructor ---- \\
	constructor(
		private cachedhttp: CachedhttpService
	) {}


	// ---- Methods ---- \\

	// ---- Public

	/**
	 * Gets app info
	 * 
	 * @returns 
	 */
	public getAppInfo() {
		const url = environment.api_endpoint + '/wp-json/arctic_excursions/app-info'
		const body = {}

		return this.cachedhttp.post(url, body)
	}


	/**
	 * Gets user data
	 * 
	 * @returns 
	 */
	public getUserData() {
		const url = environment.api_endpoint + '/oauth/me'
		const body = {}

		return this.cachedhttp.post(url, body)
	}
}
