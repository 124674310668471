import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from './cache.service';

@Injectable({
	providedIn: 'root'
})
export class CachedhttpService {

	// ---- Constructor ---- \\
	constructor(
		private http: HttpClient,
		private cache: CacheService
	) { }



	// ---- Methods ---- \\
	

	// ---- Public

	/**
	 * Calls cache with http post callback
	 * 
	 * @param url 
	 * @param options 
	 * @param refresh if data should be refreshed or returned from cache
	 * @returns 
	 */
	public post(url: string, body, options = {}, refresh: boolean = false) {
		return this.cache.getWithCallback(url + this.cache.options_prefix + new URLSearchParams(body).toString(), this.http.post(url, body, options), refresh)
	}


	// ---- Private
}
