import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Interfaces
import { iParticipant } from 'src/app/interfaces/participant';
@Component({
	selector: 'participant-info',
	templateUrl: './participant-info.component.html',
	styleUrls: ['./participant-info.component.scss'],
})
export class ParticipantInfoComponent implements OnInit {
	// ---- Variables ----\\
	@Input() title: string
	@Input() participant: iParticipant



	// ---- Constructor ---- \\
	constructor(
		private modalController: ModalController
	) { }



	// ---- Lifecycle hooks ---- \\
	ngOnInit() {
		// 
	}



	// ---- Methods ---- \\

	// ---- Public
	public dismiss() {
		this.modalController.dismiss({
			dismissed: true
		});
	}

}
