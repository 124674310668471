import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

// Services
import { AuthenticationService } from './authentication.service';
import { ToastService } from './toast.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	retries: number = 2;

	constructor(
		private auth: AuthenticationService,
		private toast: ToastService,
		private router: Router
	) {}

	intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


		// Return request without modification if login request
		if(httpRequest?.body?.grant_type === 'password' || httpRequest?.body?.grant_type === 'refresh_token' || httpRequest.url.includes('app-info')) {
			return next.handle(httpRequest)
		}

		// Get token and add it to headers
		return this.handleToken(next, httpRequest)
			.pipe(
				catchError((response) => {

					// If token is invalid refresh token and try again
					if (response.error.error === 'invalid_request') {
						return this.auth.refreshToken()
							.pipe(
								retry(this.retries),
								switchMap(() => this.handleToken(next, httpRequest)),
								catchError((refresh_response) => {
									
									// Show error message
									this.toast.presentToast(refresh_response.error.error_description, 'danger')

									// Redirect user to login page after short delay
									setTimeout(() => {
										this.router.navigate(['/'])
									}, 500)

									return throwError(refresh_response)
								})
							)
					}

					return throwError(response);
				})
			)
	}


	/**
	 * Gets a token from storage and adds it to headers of current reqeust
	 *
	 * @param next
	 * @param httpRequest
	 * @returns HttpEvent Observable
	 */
	handleToken(next, httpRequest): Observable<HttpEvent<any>> {
		return from(this.auth.getToken())
			.pipe(
				retry(this.retries),
				switchMap((token) => {
					return next.handle(httpRequest.clone({
						setHeaders: { Authorization: 'Bearer ' + token },
					})) as Observable<HttpEvent<any>>
				})
			)
	}
}
