import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	private _storage: Storage | null = null;

	constructor(
		private storage: Storage
	) {
		this.init();
	}

	public wakeup() {
		// Empty method to wake up service and trigger the contructor
	}

	async init() {
		const storage = await this.storage.create();
		this._storage = storage;
	}


	/**
	 * 
	 * @param key 
	 * @returns promise
	 */
	public get(key: string): Promise<any> {
		return this._storage?.get(key);
	}

	/**
	 * 
	 * @param key 
	 * @param value 
	 * @returns promise
	 */
	public set(key: string, value: any): Promise<any> {
		return this._storage?.set(key, value);
	}


	/**
	 * 
	 * @param key 
	 * @returns promise
	 */
	public remove(key: string): Promise<any> {
		return this._storage.remove(key);
	}


	/**
	 * Get all keys from storage
	 * 
	 * @returns promise
	 */
	public async all(): Promise<any> {
		const result = await this._storage.keys().then(
			data => {
				return data
			}
		)

		return result
	}
}