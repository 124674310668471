import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { catchError, tap } from 'rxjs/operators';
import { iAppInfo } from './interfaces/app-info';
import { AppService } from './services/app.service';
import { AuthenticationService } from './services/authentication.service';
import { StorageService } from './services/storage.service';


@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

	// ---- Variables --- \\
	
	public pages = [
		{
			title: 'Startside',
			url: '/tabs/overview',
			icon: 'home'
		},
		{
			title: 'Opret afgang',
			url: '/tabs/create-departure',
			icon: 'add-circle'
		},
		{
			title: 'Oplevelser',
			url: '/tabs/departures',
			icon: 'iceberg-upper'
		},
	];

	public ios: boolean
	public appInfo: iAppInfo


	// ---- Constructor ---- \\

	constructor(
		private storage: StorageService,
		private auth: AuthenticationService,
		private router: Router,
		private alertController: AlertController,
		private app: AppService
	) {
		// Wakeup call
		this.storage.wakeup()
	}



	// ---- Lifecycle hooks ---- \\

	ngOnInit() {
		// Wait for wakeup befor calling info
		setTimeout(() => {
			this.getInfo()
		}, 50)
	}


	/**
	 * Show support alert with temporary support-information
	 */
	public async showSupportAlert() {
		const alert = await this.alertController.create({
			header: 'Brug for support?',
			message: 'Har du spørgsmål eller brug for hjælp til brug af denne app, kontakt Arctic Excursions på tlf. ' + this.appInfo.contact_phone,
			buttons: [
				{
					text: 'Luk'
				},
				{
					text: 'Ring op',
					handler: () => {
						window.open('tel:' + this.appInfo.contact_phone, '_self')
					}
				}
			]
		})

		await alert.present();		
	}


	/**
	 * Logout user and send to user to login screen
	 */
	public async logout() {
		const alert = await this.alertController.create({
			header: 'Vil du logge ud?',
			buttons: [
				{
					text: 'Fortryd'
				},
				{
					text: 'Log af',
					handler: () => {
						this.auth.logout()
						this.router.navigate(['/'])
					}
				}
			]
			});

		await alert.present();
	}



	// ---- Private ---- \\

	public getInfo() {
		this.app.getAppInfo().subscribe(
			(response: iAppInfo) => {
				this.appInfo = response
			},
			(error) => {
				this.appInfo = this.app.appInfoFallback
			}
		)
	}
}
