import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

	
	// ---- Constructor ---- \\
	constructor(
		private auth: AuthenticationService,
		private router: Router
	){}



	// ---- Public methods --- \\
	
	async canActivateChild(): Promise<boolean> {

		const check = await this.auth.validateToken()

		if (check === true) {
			return true
		}
		else {
			this.router.navigate(['/login'])
			return false
		}
	}
}