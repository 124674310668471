import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root'
})
export class CacheService {

	// ---- Variables ---- \\
	private cache_prefix = 'cache_'
	public options_prefix = '/'


	// ---- Constructor ---- \\
	constructor(
		private storage: StorageService
	) {}



	// ---- Methods ---- \\


	// ---- Public

	/**
	 * Get cached data and fallback to callback data if expired or
	 * if item does not exist in cache.
	 * 
	 * @param key 
	 * @param callback 
	 * @param refresh if data should be refreshed or returned from cache
	 * @returns Observable
	 */
	public getWithCallback(key: string, callback, refresh: boolean = false ) {
		const date = new Date()

		return this.get(key)
			.pipe(
				switchMap((item: any) => {
					
					// Return cached value if it exists, and not expired, and not refreshed
					if(item && item.expires >= date.getTime() && !refresh) {
						return of(item.value)
					}
					else if(item) {
						this.remove(key)
					}
					

					// Else return callback and set cache on response
					return callback.pipe(tap((response) => {
						this.set(key, response)
					}))
				})
		
			)
		
	}


	/**
	 * Get cached item from storage
	 * 
	 * @param key 
	 * @returns observable
	 */
	public get(key: string) {
		return from(this.storage.get(this.cache_prefix + key))
	}


	/**
	 * Removes item from cache
	 * 
	 * @param key 
	 */
	public async remove(key) {
		await this.storage.remove(this.cache_prefix + key)
	}


	/**
	 * Clear all endpoints with the cache_prefix
	 */
	public async clearCache() {
		await this.storage.all().then(keys => {
			for (let key of keys) {
				if(key.match(new RegExp('^'+this.cache_prefix))) {
					this.storage.remove(key)
				}
			}
		})
	}



	// ---- Private

	/**
	 * Save an item in storage with an expiration timestamp.
	 * 
	 * @param key 
	 * @param value 
	 */
	private async set(key: string, value: any) {
		const date = new Date()
		const expiration = 60 * 60 * 1000;

		// Save in storage
		await this.storage.set(this.cache_prefix + key, {
			expires: date.getTime() + expiration,
			value: value
		})
	}

}
