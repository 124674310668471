import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, switchMap, timestamp } from 'rxjs/operators'
import { Observable, throwError, from, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

// Services
import { StorageService } from './storage.service';
import { CacheService } from './cache.service';


@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	// ---- Variables ---- \\
	private url	= environment.api_endpoint + '/oauth/token'
	private authToken = environment.auth_token
	private authState = new BehaviorSubject(false)
	


	// ---- Constructor ---- \\
	constructor(
		private http: HttpClient,
		private storage: StorageService,
		private cache: CacheService
	) {}



	// ---- Public ---- \\


	/**
	 * Login the user by authenticating and setting tokens in storage
	 * 
	 * @param username 
	 * @param password 
	 * @returns 
	 */
	public login(username: string, password: string) {
		if(username && password) {
			return this.requestTokenByCredentials(username, password);
		}

		// TODO Return error if username or password is missing
	}


	/**
	 * Check if user is logged in by the authState
	 * 
	 * @returns BehaviorSubject value
	 */
	public isLoggedIn() {
		return this.authState.value;
	}


	/**
	 *  Gets refresh token and requests new token
	 */
	public refreshToken() {

		return from( this.getRefreshToken() )
			.pipe(
				switchMap(refresh_token => {
					return this.requestTokenByRefreshToken(refresh_token)
				})
			)
	}


	/**
	 * Logout the user by removing all auth tokens, timestamp and auth state
	 */
	public logout() {
		// Remove login related items from storage
		this.storage.remove('token')
		this.storage.remove('token-timestamp')
		this.storage.remove('refresh-token')

		// Set login state to false
		this.authState.next(false);

		// Remove cached items
		this.cache.clearCache()
	}


	/**
	 * Validate the token against token timestamp
	 * Primary used in AuthGuard
	 * 
	 * @returns true/false
	 */
	public async validateToken(): Promise<any> {

		const token = await this.getToken().then((token) => {
			return token ? true : false
		})

		if(token === true) {
			// Validate timestamp
			const tokenTime = await this.getTokenTimestamp().then((tokenTime) => {
				const unixTimeNow = Math.round((new Date()).getTime())

				return (tokenTime >= unixTimeNow) ? true : false
			})

			if(tokenTime === true) {
				// Update authState and carry on!
				this.authState.next(true)
				return true
			}
		}
		else{
			// Token is expired - force logout
			this.logout()
			return false
		}

	}


	/**
	 * Returns access token
	 */
	public getToken(): Promise<any> {
		return this.storage.get('token')
	}


	/**
	 * Returns token timeout-stamp
	 */
	 public getTokenTimestamp(): Promise<any> {
		return this.storage.get('token-timestamp');
	}



	// ---- Private ---- \\

	/**
	 * Request a token by user cridentials for futher API communication
	 * 
	 * @param username 
	 * @param password 
	 * @returns 
	 */
	private requestTokenByCredentials(username: string, password: string) {
		const body = {
			grant_type: 'password',
			username,
			password
		}

		return this.requestToken(body)
	}


	/**
	 * Request a refresh token for refreshing main token
	 * 
	 * @param refresh_token 
	 * @returns 
	 */
	private requestTokenByRefreshToken(refresh_token: string) {
		const body = {
			grant_type: 'refresh_token',
			refresh_token
		}

		return this.requestToken(body)
	}


	/**
	 * Request a token by body headers
	 * 
	 * @param body
	 * @returns 
	 */
	private requestToken(body): Observable<any> {
		const url = this.url
		const options = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				Authorization: 'Basic ' + this.authToken
			})
		}

		return this.http.post(url, body, options)
			.pipe(
				tap((response: any) => {
					if(response.access_token) {
						// Save tokens to storage
						this.saveToken(response.access_token)
						this.saveTokenTimeout(response.expires_in)
						this.saveRefreshToken(response.refresh_token)

						// Approve user access
						this.authState.next(true);
					}
					else if(response.error) {
						// error message using `error_description`
						return throwError(new Error(response.error))
					}
					else {
						// default error
						return throwError(new Error('Something went wrong'))
					}
				})
			)
	}


	/**
	 * Returns refresh token
	 */
	private getRefreshToken(): Promise<any> {
		return this.storage.get('refresh-token');
	}


	/**
	 * Saves access token in storage with `token` identifier
	 * 
	 * @param token 
	 */
	private saveToken(token: string) {
		this.storage.set('token', token);
	}


	/**
	 * Current time + token expiration time for auth-check
	 * 
	 * @param timestamp 
	 */
	private saveTokenTimeout(expiration: number) {
		this.storage.set('token-timestamp', (expiration * 1000) + Date.now());
	}


	/**
	 * Saves refresh token in storage with `refresh-token` identifier
	 * 
	 * @param token 
	 */
	private saveRefreshToken(token: string) {
		this.storage.set('refresh-token', token);
	}

}