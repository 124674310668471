import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

// Services
import { CachedhttpService } from './cachedhttp.service';
import { CacheService } from './cache.service';

// Interfaces
import { iDeparture } from '../interfaces/depature';
import { catchError, map } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DeparturesService {

	// ---- Variables ----\\



	// ---- Constructor ---- \\
	constructor(
		private http: HttpClient,
		private cachedhttp: CachedhttpService,
		private cache: CacheService
	) { }



	// ---- Lifecycle hooks ---- \\



	// ---- Methods ---- \\

	// ---- Public ---- \\

	/**
	 * Get all departures. Will get cached departures if available.
	 * 
	 * @param refresh if data should be refreshed or returned from cache
	 * @returns cached http post
	 */
	public getDepartures(refresh: boolean = false) {
		const url = environment.api_endpoint + '/oauth/get-departures'
		const body = {}
		const options = {}


		return this.cachedhttp.post(url, body, options, refresh).pipe(
			map((response: any[]) => {

				// Make sure that departures contain correct params, and filter out bad ones
				const valid_departures = response.filter((departure) => departure.id !== undefined)

				// Throw error and clear cache from bad response
				if(valid_departures.length === 0) {
					throwError(new Error('departure format is not valid'))

					// Remove from cache so we dont save unwanted data
					this.cache.remove(url)
				}

				return valid_departures
			}),
			catchError(err => {
				return of([]);
			})
		)
	}


	/**
	 * Get participants for a specific departure
	 * 
	 * @param id 
	 * @returns cached http post
	 */
	public getParticipants(id: string) {
		const url = environment.api_endpoint + '/oauth/get-travelers'

		// We have to create a formData object for the backend to see our body
		const body =  new FormData()
		body.append(
			'departure_id', id
		)


		return this.cachedhttp.post(url, body)
	}


	/**
	 * Formats array of departures into `iDeparture[]`
	 * 
	 * @param departures 
	 * @returns iDeparture[]
	 */
	public formatDepartures(departures: any[]): iDeparture[] {
		return departures.map(departure => {
			return this.formatDeparture(departure)
		})
	}


	/**
	 * Formats departure from backend call into 'iDeparture' structure
	 * 
	 * @param departure 
	 * @returns iDeparture
	 */
	public formatDeparture(departure): iDeparture {

		const date = new Date(departure.timestamp * 1000)

		return {
			id: departure.id,
			name: departure.tour.title,
			date: date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(', ', ' '),
			location: departure.tour.location,
			bookings: departure.traveler_count.ae + departure.traveler_count.app,
			imagePath: departure.tour.image.medium,
			reserved: departure.ae_reserved
		}
	}


	/**
	 * Cancel a departure
	 * 
	 * @param id 
	 * @returns cached http post
	 */
	public cancelDeparture(id: string) {
		const url = environment.api_endpoint + '/oauth/cancel-departure'
		
		const body =  new FormData()
		body.set(
			'departure_id', id
		)

		return this.http.post(url, body)
	}



	/**
	 * Create a new departure
	 * 
	 * @param postData 
	 * @returns cached http post
	 */
	public createDeparture(postData: FormData) {
		const url = environment.api_endpoint + '/oauth/create-departure'
		const endpointAccepts = ['tour_id', 'timestamp']

		const body =  new FormData()

		for(let [key, value] of Object.entries(postData)) {
			if(endpointAccepts.includes(key)) {
				body.set(key, (value as string))
			}
		}

		return this.http.post(url, body)
	}



	/**
	 * Edit a departure
	 * 
	 * @param postData 
	 * @returns cached http post
	 */
	public editDeparture(postData: FormData) {
		const url = environment.api_endpoint + '/oauth/edit-departure'
		const endpointAccepts = ['departure_id', 'timestamp']
		
		const body =  new FormData()

		for(let [key, value] of Object.entries(postData)) {
			if(endpointAccepts.includes(key)) {
				body.set(key, (value as string))
			}
		}

		

		return this.http.post(url, body)
	}
	
	
	/**
	 * Clear a specific departure from the cache
	 * 
	 * @param id 
	 * @returns 
	 */
	public cacheClearDeparture(id: string) {
		const key = environment.api_endpoint + '/oauth/get-departures/departure_id=' + id

		return this.cache.remove(key)
	}


	/**
	 * Clear cache for depature list
	 * 
	 * @returns 
	 */
	public cacheClearDepartures() {
		const key = environment.api_endpoint + '/oauth/get-departures' + this.cache.options_prefix

		return this.cache.remove(key)
	}

}
