import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { iDeparture } from 'src/app/interfaces/depature';

// Interfaces
import { iParticipants } from 'src/app/interfaces/participants';
import { DeparturesService } from 'src/app/services/departures.service';

@Component({
	selector: 'app-participant-distribution',
	templateUrl: './participant-distribution.component.html',
	styleUrls: ['./participant-distribution.component.scss'],
})
export class ParticipantDistributionComponent implements OnInit {

	// ---- Variables ----\\
	@Input() title: string
	@Input() departureID: string
	@Input() departure: iDeparture
	participants: iParticipants

	participants_app: number = 0
	participants_ae: number = 0



	// ---- Constructor ---- \\
	constructor(
		private modalController: ModalController,
		private departuresService: DeparturesService
	) { }



	// ---- Lifecycle hooks ---- \\
	ngOnInit() {

		if(this.departureID) {
			// Get list of participants by departure id
			this.departuresService.getParticipants(this.departureID).subscribe(
				(participants: iParticipants) => {
					if(participants?.ae.length > 0 || participants?.app.length > 0) {
						this.participants = participants
						this.countParticipants(participants)
					}
				}
			)
		}

	}



	// ---- Methods ---- \\

	// ---- Public
	public dismiss() {
		this.modalController.dismiss({
			dismissed: true
		});
	}


	private countParticipants(participants) {
		this.participants_app = participants?.app.length
		this.participants_ae = participants?.ae.length
	}
}
